<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        a) If for a given reaction,
        <stemble-latex content="$\Delta\text{H = }$" />
        <number-value :value="enthalpy" unit="\text{kJ/mol}" />
        and
        <stemble-latex content="$\Delta\text{S = }$" />
        <number-value :value="entropy" unit="\text{J K}^{-1}\text{mol}^{-1}," />
        determine the value of
        <stemble-latex content="$\Delta\text{G}$" />
        (in kJ/mol) for the reaction at
        <stemble-latex content="$25^\circ\text{C.}$" />
      </p>

      <calculation-input
        v-model="inputs.deltaG"
        class="mb-6"
        dense
        prepend-text="$\Delta\text{G}_{\text{rxn}}:$"
        append-text="$\text{kJ/mol}$"
        :disabled="!allowEditing"
      />

      <p class="mb-0">
        b) In what direction, if any, is the reaction spontaneous at this temperature?
      </p>

      <v-radio-group v-model="inputs.spontDirection" class="pl-8" :disabled="!allowEditing">
        <v-radio
          v-for="option in options"
          :key="option.value"
          class="d-inline-block"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.expression" />
          </template>
        </v-radio>
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import StembleLatex from '@/tasks/components/StembleLatex';
import NumberValue from '../NumberValue';

export default {
  name: 'Question291',
  components: {
    CalculationInput,
    StembleLatex,
    NumberValue,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        deltaG: null,
        spontDirection: null,
      },
      options: [
        {
          expression: 'The reaction is spontaneous as described',
          value: 'forward',
        },
        {
          expression: 'The reaction is spontaneous in the reverse direction',
          value: 'reverse',
        },
        {expression: 'More information is required', value: 'unknown'},
        {
          expression: 'The reaction is spontaneous in both directions',
          value: 'both',
        },
      ],
    };
  },
  computed: {
    enthalpy() {
      return this.taskState.getValueBySymbol('enthalpy').content;
    },
    entropy() {
      return this.taskState.getValueBySymbol('entropy').content;
    },
  },
};
</script>
